<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <div class="data_search">
                <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="72px">
                    <el-form-item label="供应单号：" prop="code">
                        <el-input v-model="listQuery.code" placeholder="请输入供应单号" @keyup.enter.native="toSearchList"></el-input>
                    </el-form-item>

                    <el-form-item label="供应标题：" prop="title">
                        <el-input v-model="listQuery.title" placeholder="请输入供应标题" @keyup.enter.native="toSearchList"></el-input>
                    </el-form-item>

                    <el-form-item label="商品名称：" prop="goodsName">
                        <el-input v-model="listQuery.goodsName" placeholder="请输入商品名称" @keyup.enter.native="toSearchList"></el-input>
                    </el-form-item>

                    <el-form-item class="last">
                        <el-button type="primary" :loading="isSearchLoading"  @click="toSearchList">查 询</el-button>
                        <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="data_group mt18">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="$router.push('/supplyBuying/supply/add')">发布供应单</el-button>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column type="index" width="50" label="#" align="center"></el-table-column>
                    <el-table-column prop="code" label="供应单号" width="170"></el-table-column>
                    <el-table-column prop="title" label="供应标题" width="160" align="center"></el-table-column>
                    <el-table-column prop="goodName" label="商品名称" min-width="120px" align="center"></el-table-column>
                    <el-table-column label="供应数量" min-width="100px" align="center">
                        <template slot-scope="scope">
                            {{scope.row.num}}{{scope.row.unitName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="供应价" min-width="160px" align="center">
                        <template slot-scope="scope">
                            ￥{{scope.row.price}} ({{getPriceStatus(scope.row.priceStatus)}})
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="详细说明" min-width="180px"></el-table-column>
                    <el-table-column label="状态" min-width="100px" align="center">
                        <template slot-scope="scope">
                            <div class="status green" v-if="scope.row.isRun === 1">进行中</div>
                            <div class="status red" v-if="scope.row.isRun === 2">已关闭</div>
                            <div class="status info" v-if="scope.row.isRun === 3">已过期</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receiptAt" label="预出售日期" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="stopAt" label="供应截止日期" min-width="140px" align="center"></el-table-column>
                    <el-table-column label="审核状态" min-width="100px" align="center">
                        <template slot-scope="scope">
                            <div class="status blue" v-if="scope.row.auditStatus === 1">待审核</div>
                            <div class="status green" v-if="scope.row.auditStatus === 2">已通过</div>
                            <div class="status info" v-if="scope.row.auditStatus === 3">已关闭</div>
                            <div class="status red" v-if="scope.row.auditStatus === 4">已驳回</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="130" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="$router.push({path: '/supplyBuying/supply/edit', query: {id: scope.row.id}})" v-if="scope.row.isRun === 1 && scope.row.auditStatus !== 3">编辑</el-button>
                            <el-button type="text" @click="cancelData(scope.row.id)" v-if="scope.row.isRun !== 2">关闭</el-button>
                            <el-button type="text" @click="delData(scope.row.id)" v-if="scope.row.isRun === 2">删除</el-button>
                            <el-button type="text" @click="$router.push({path: '/supplyBuying/supply/detail', query: {id: scope.row.id}})">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {imageURL} from "../../../../utils/config";
  import {getSupply, postSupplyCancel, delSupply} from "../../../../api/admin/supplyBuying";
  const pagination = () => import('../../../../components/pagination/admin')
  export default {
    name: "supplyBuyingSupply",
    data () {
      return {
        listQuery: {
          code: '',
          title: '',
          goodsName: '',
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        imageURL: imageURL
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getSupply(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
      cancelData (id) {
        this.$confirm('此操作将永久关闭该供应单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postSupplyCancel(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '供应单关闭成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      delData (id) {
        this.$confirm('确认删除当前供应单？删除后不可恢复', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delSupply(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '供应单删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {pagination}
  }
</script>
